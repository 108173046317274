import { Configuration, ENV, SdkInitResult } from '../../shared/types'
import { subscribeToTriggers } from '../core/triggers'
import { initGlobalObserver } from '../core/dom_mutator'
import { TargetingContext } from '../core/targeting-context'
import { getJitsuClient, reportError } from '../core/common/jitsu'
import { installCustomFonts } from '../core/common/fonts'
import { removeFocusTrapFromBackdrop } from '../core/common/focus_trap_removal'
import { error } from '../core/common/log'
import { ALLOCATION_DONE, awaitCondition, maybe } from '../../shared/util'
import {
  assertNotBlocked,
  BLOCKED_ERR,
  consolidateUserId,
  destroy,
  getConfiguration,
  getShopifyCurrencyConverter,
  handleBackForwardCache,
  initShopApi,
  persist,
  setGlobals,
  sleep,
} from '../core/init'
import { reloadOnDeviceChange } from '../core/triggers/device'
import { antiFlickerReveal } from '../../shared/bootstrap'
import { initializeCustomerTags } from '../../shared/helpers'

export async function reload(
  env: ENV,
  storeAlias: string,
  graceMS: number = 1000
) {
  await destroy()
  await sleep(graceMS)
  await initialize(env, storeAlias)
}

export async function commonInitialization(
  env: ENV = ENV.PROD,
  storeAlias: string,
  targetingContextOverride?: Partial<TargetingContext>
): Promise<SdkInitResult> {
  const converterPromise = getShopifyCurrencyConverter()
  initializeCustomerTags()
  const allocRespPromise = getConfiguration(
    storeAlias,
    env,
    targetingContextOverride
  )
  const [{ configuration, tracking }] = await Promise.all([
    allocRespPromise,
    converterPromise,
  ])
  persist(configuration.flags)
  await awaitSpaInitialized()
  setGlobals(storeAlias, env, configuration, reload,
    getCurrentProduct())
  initShopApi(
    configuration,
    formatLocaleMoney,
    () => true
  )
  consolidateUserId(configuration.uid, tracking.id)
  removeFocusTrapFromBackdrop()
  const jitsu = getJitsuClient()
  return { configuration, jitsu }
}

async function awaitSpaInitialized() {
  await awaitCondition(() => !!maybe(() => typeof window.visually.addCartItem !== 'undefined'), 20, 50)
}

export function dispatchAllocationDone(resp: Configuration) {
  window.loomi_ctx.sdkReady = true
  window.dispatchEvent(
    new CustomEvent(ALLOCATION_DONE, {
      detail: { config: resp },
    }),
  )
}

export async function initialize(env: ENV = ENV.PROD, storeAlias: string) {
  try {
    assertNotBlocked()
    const { jitsu, configuration } = await commonInitialization(
      env,
      storeAlias
    )
    if (maybe(() => !!configuration.flags['kill-switch'])) {
      return
    }
    await initGlobalObserver()
    installCustomFonts(configuration.experiments)
    subscribeToTriggers(jitsu, configuration)
    handleBackForwardCache(env, storeAlias, reload)
    reloadOnDeviceChange()
    dispatchAllocationDone(configuration)
  } catch (e) {
    if (maybe(() => (e as any).message) === BLOCKED_ERR) {
      return
    }
    error('failed initialising sdk', e)
    const err = e as any
    reportError()(
      maybe(
        () =>
          `init failed sdk: ${err.toString()}, alias:${storeAlias}, stack: ${err.stack.toString()}`
      )
    )
  } finally {
    antiFlickerReveal()
  }
}

export function formatLocaleMoney(amount: number, fixed_price_currency?: string) {
  const currency = maybe(() => window.visually!.currency, 'USD')!
  const locale = maybe(() => window.visually!.locale, 'en-US')

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
  if (fixed_price_currency) {
    return formatter.format(amount)
  }

  let finalAmount = window.loomi_ctx.Currency.convert(amount, 'USD', currency)
  if (
    maybe(() => window.visually.flags['exchange-fee'])
  ) {
    finalAmount *= 1.02
  }
  if (
    maybe(() => window.visually.flags['op-locale-currency-no-round'] !== true)
  ) {
    finalAmount = Math.round(finalAmount)
  }

  return formatter.format(finalAmount)
}

function getCurrentProduct() {
  return {
    productId: maybe(() => window.loomi_ctx.productId!.toString())!,
    variantId: maybe(() => window.loomi_ctx.variantId!.toString())!
  }
}
