import { DeclarativeBlock } from '../index'
import { LoadingEnv, Product, RecommendationOptions } from '../sdk/core/dom_mutator/loading_sdk/recommendations_models'
import { CartBase } from './shopifyTypes'
import { JitsuClient } from '../analytics/core/jitsu/interface'
import { ClientAnalytics } from './hydrogen1-client-analytics'
import { CartWithActions as H1CartWithActions } from '../sdk/hydrogen/hydrogen1'
import { LoyaltyContext } from '../sdk/core/targeting-context/loyalty/common'
import { CartWithActions, Location } from './hydrogen2-types/types/cart-types'
import { ShopifyPageViewPayload } from './hydrogen2-types/types/analytics-types'
import { ProductHookValue } from './hydrogen2-types/types/ProductProvider'
import { ProductSeenInSession } from '../sdk/core/targeting-context'
import { CurrentProduct } from '../sdk/spa/types'

export interface JitsuTrackingContext {
  cartId?: string;
  page?: {
    pageType?: string;
  };
  product?: {
    id: string;
  };
  variant?: {
    id: string;
  };
  clientId: string;
  userCanBeTracked: boolean;
  userDataCanBeSold: boolean;
}

declare global {
  interface Window {
    vslyNotShopify?: boolean
    __st?: {
      cid?: string;
    };
    vslyDeferResources?: boolean;
    vslyDeferWidgets?: boolean;
    vslyDeferWidgetsMargin?: string;
    vslyAntiFlickerReveal?: () => void;
    ga: any;
    gtag: any;
    dataLayer: any;
    vslyNativeFetch?: (input: RequestInfo | URL, init?: RequestInit) => Promise<Response>;
    vslyIntegrationType?: 'static' | 'spa'
    vslyDesktopBreakpoint?: number
    // @ts-ignore
    visually: {
      recsPerVariant?: Record<string, number>
      country?: string;
      hydrogen2Product?: ProductHookValue;
      hydrogen2Location?: Location;
      hydrogen2ClientAnalytics?: ShopifyPageViewPayload
      hydrogen2CartWithActions?: CartWithActions;
      hydrogen2ProductConnect?: (product: ProductHookValue) => void;
      hydrogenCartDrawerConnect?: (openCartDrawer: () => void) => void
      hydrogen2Connect?: (
        analyticsClient: ShopifyPageViewPayload,
        cartWithActions: CartWithActions,
        location: Location,
        clientName: string,
        clientToken: string,
        env?: ENV,
      ) => void;

      hydrogen1CartWithActions?: H1CartWithActions;
      cartAddAttributes?: (attrs: { attributes: [{ key: 'foo', value: 'bar' }] }, cb: (cart: any) => void) => void
      hydrogen1Connect?: (
        analyticsClient: ClientAnalytics,
        cartWithActions: H1CartWithActions,
        clientName: string,
        clientToken: string,
        env?: ENV,
      ) => void;
      hydrogen1ClientAnalytics?: ClientAnalytics;

      addCartItem?: (variantId: string, quantity: number) => Promise<any>;
      cartClear?: () => void;
      openDrawer?: () => void
      updateCartAttributes?: (attributes: { attributes: Array<{ key: string, value: string }> }, cb: (cart: any) => void) => void

      currency?: string;
      locale?: string;
      pageType?: 'homepage' | 'collection' | 'product' | string;

      onCartChanged?: (cart: any) => void;
      onUserIdChange?: (userId: string) => void;
      visuallyConnect?: (instrument: any) => void
      productChanged?: (product: CurrentProduct) => void
      localeChanged?: (locale: string) => void
      customerTagsChanged?: (newTags: [string]) => void
      currencyChanged?: (currency: string) => void
      pageTypeChanged?: (pageType: string) => void

      flags?: any;
      reload?: () => Promise<void>;
      sdk_api?: {
        recommendProducts: (env?: LoadingEnv) => Promise<Array<Product>>;
        addToCart: (
          quantity: number,
          variantId: number,
          openDrawer: boolean,
          properties: any,
        ) => Promise<void>;
      };
    };
    // @ts-ignore
    vsly: {
      env?: number;
      alias?: string;
      jitsuApiKey?: string;
    };
    // @ts-ignore
    SLIDECART_UPDATE?: () => any;
    // @ts-ignore
    SLIDECART_OPEN?: () => any;
    // @ts-ignore
    SLIDECART_CLOSE?: () => any;
    // @ts-ignore
    loomi_api: any;
    ShopifyAnalytics: {
      meta: {
        selectedVariantId?: string;
        product?: {
          id: number;
        };
      };
    };
    Shopify:
    | {
      currency: {
        active?: string;
        rate?: string;
      };
      locale?: string;
      getCart: () => any;
    }
    | CheckoutDetails
    | any;
    vsly_blocked?: boolean;
    vsly_init?: boolean;
    vsly_analytics_init: boolean;
    // @ts-ignore
    loomi_ctx: {
      testedThemes?: Array<number>;
      maintainCartAttributes?: (cart?: CartBase) => void;
      audiences?: string[] | undefined;
      gtags?: Array<(tagId: string) => void>
      tagIds?: Set<string>
      last_vid_atc?: { vid: string, ts: number },
      Currency: {
        rates: any, convert: (price: number, from: string, to: string) => number;
      };
      sessionProducts?: Array<ProductSeenInSession>;
      cart?: CartBase;
      loyalty?: LoyaltyContext;
      pageshow?: boolean;
      storeAlias?: string;
      updateRecsOpts?: Map<string, (opts: RecommendationOptions) => Promise<RecommendationOptions> | RecommendationOptions>,
      awaitRecs?: Map<string, () => Promise<void>>,
      ctags?: Array<string>;
      current_product?: {
        id: number;
        "variants": [
          {
            "id": number,
            "price": number,
            "iq": number
          }
        ],
        "oos": boolean,
        "price": number // max variant price in cents
      };
      jitsuKey?: string;
      userId?: string;
      session?: Session;
      clientId?: string;
      productId?: string;
      sdkReady?: boolean;
      variantId?: string;
      deviceOverride?: 'm' | 'd';
      cartRefreshed?: boolean;
      variantPrice?: number;
      productMeta?: string;
      env?: ENV;
      apply?: (experiment: UseCase) => any;
    };
    // @ts-ignore
    loomi: {
      conf?: Configuration;
      jitsu?: JitsuClient;
      reportSdkErr?: (err: string) => Promise<Response> | undefined;
      reportAnalyticsErr?: (err: string) => Promise<Response> | undefined;
    };
  }
}

export interface SdkInitResult {
  configuration: Configuration;
  jitsu: JitsuClient;
}

export enum TriggerType {
  JSCondition = 'jsCondition',
  JSFunction = 'jsFunction',
  JSEvent = 'jsEvent',
  Selector = 'selector',
  ElementEvent = 'elementEvent',
  PageLoad = 'pageLoad',
  Timeout = 'timeout',
  Inactivity = 'inactivity',
  ExitIntent = 'exitIntent',
  Conjunction = 'conjunction',
}

export type Selector = { selector: string; type: TriggerType.Selector }
export type ElementEvent = { selector: string; type: TriggerType.ElementEvent, once?: boolean, name: string }
export type JSCondition = {
  timeoutMillis: number;
  retries: number;
  type: TriggerType.JSCondition;
  code: string;
}
export type JSFunction = {
  code: string;
  type: TriggerType.JSFunction
}

export type JSEvent = {
  type: string;
  jsEvent: {
    name: string;
    matchCode: string;
  };
}

export type ExitIntent = { type: TriggerType.ExitIntent }
export type PageLoad = { type: TriggerType.PageLoad }
export type Timeout = { timeoutMillis?: number; type: TriggerType.Timeout }
export type Inactivity = { timeoutMillis?: number; type: TriggerType.Inactivity }

export type Conjunction = {
  condition: Selector | Timeout | ExitIntent;
  jsCond: JSCondition;
  type: TriggerType.Conjunction;
}

export enum ExperienceStatus {
  PAUSED = 4,
}

export type Trigger = Selector
| ElementEvent
| PageLoad
| Timeout
| Inactivity
| ExitIntent
| JSCondition
| JSEvent
| JSFunction
| Conjunction

export type UseCase = {
  name: string;
  variant: string;
  version?: number;
  gaName?: string;
  chance?: number;
  gaVariant?: string;
  status?: ExperienceStatus;
  trigger: Trigger
  code: DeclarativeBlock[];
  clientTargetingFormula?: string;
  publishedAt?: number;
}

export interface Configuration {
  uid: string;
  experiments: Array<UseCase>;
  audiences?: string[];
  flags: any;
  shopApi?: Array<{ windowKey: string; js: string }>;
  queryHistory?: Array<QueryHistoryEntry>;
  currentVariant?: {
    pid: number;
    vid: number;
    price: number;
    variantIds: Array<number>;
    meta: string;
  };
  testedThemes?: Array<number>
  noActiveThemeTests?: boolean;
}

export enum ENV {
  LOCAL,
  STAGE,
  PROD,
  TEST,
}

export interface CheckoutStatus {
  apiHost: string;
  assetsPath: string;
  i18n: {
    orders: {
      order_updates: {
        title: string;
      };
    };
    qr_code: {
      title: string;
      subtitle: string;
      send_link_to_phone: string;
    };
  };
  isOrderStatusPage: boolean;
  token: string;
  hasSellingPlan: boolean;
  OrderStatus: {};
}

export interface Currency {
  active: string;
  rate: string;
}

export interface Theme {
  name: string;
  id: number;
  theme_store_id: number;
  role: string;
  handle: string;
  style: {
    id?: any;
    handle?: any;
  };
}

export interface TaxLine {
  price: string;
  rate: number;
  title: string;
  channel_liable: boolean;
}

export interface ShopMoney {
  amount: string;
  currency_code: string;
}

export interface PresentmentMoney {
  amount: string;
  currency_code: string;
}

export interface TotalPriceSet {
  shop_money: ShopMoney;
  presentment_money: PresentmentMoney;
}

export interface LineItem {
  id: string;
  key: string;
  product_id: number;
  variant_id: number;
  sku: string;
  vendor: string;
  title: string;
  variant_title: string;
  image_url: string;
  taxable: boolean;
  requires_shipping: boolean;
  gift_card: boolean;
  price: string;
  compare_at_price?: any;
  line_price: string;
  properties: {};
  quantity: number;
  grams: number;
  fulfillment_service: string;
  applied_discounts: any[];
  discount_allocations: any[];
  tax_lines: {
    price: string;
    rate: number;
    title: string;
    channel_liable: boolean;
  }[];
}

export interface GiftCard {
  id: number;
  balance: string;
  amount_used: string;
  last_characters: string;
}

export interface ShippingRate {
  handle: string;
  price: string;
  title: string;
  tax_lines: {
    rate: string;
    price: string;
    title: string;
  }[];
}

export interface ShippingAddress {
  id: number;
  first_name: string;
  last_name: string;
  phone?: any;
  company?: any;
  address1: string;
  address2: string;
  city: string;
  province?: any;
  province_code?: any;
  country: string;
  country_code: string;
  zip: string;
}

export interface BillingAddress {
  id: number;
  first_name: string;
  last_name: string;
  phone?: any;
  company?: any;
  address1: string;
  address2: string;
  city: string;
  province?: any;
  province_code?: any;
  country: string;
  country_code: string;
  zip: string;
}

export interface Checkout {
  created_at: Date;
  currency: string;
  customer_id: number;
  customer_locale: string;
  email: string;
  location_id?: any;
  order_id: number;
  payment_due: string;
  payment_url: string;
  phone?: any;
  presentment_currency: string;
  reservation_time?: any;
  reservation_time_left: number;
  requires_shipping: boolean;
  source_name: string;
  source_identifier?: any;
  source_url?: any;
  subtotal_price: string;
  taxes_included: boolean;
  tax_exempt: boolean;
  tax_lines: TaxLine[];
  token: string;
  total_price: string;
  total_price_set: TotalPriceSet;
  total_tax: string;
  updated_at: Date;
  version: string;
  line_items: LineItem[];
  gift_cards: GiftCard[];
  shipping_rate: ShippingRate;
  shipping_address: ShippingAddress;
  credit_card?: any;
  billing_address: BillingAddress;
  discount?: any;
}

export interface CheckoutDetails {
  Checkout: CheckoutStatus;
  shop: string;
  locale: string;
  currency: Currency;
  country: string;
  theme: Theme;
  cdnHost: string;
  checkout: Checkout;
}

export interface QueryHistoryEntry {
  k: string;
  v: string;
  ts: number;
}

export interface VisitedPage {
  name: string;
  ts: Date;
  search?: string;
}

export const previewParamName = 'lmi_preview'

export interface Session {
  id: string,
  utm_campaign: string,
  start_at: Date
  last_interaction: Date
  landing_page?: string
}

export interface Context {
  _USE_CASE: string;
  _USE_CASE_VARIANT: string;
  _USE_CASE_GA?: string;
  _USE_CASE_GA_VARIANT?: string;
  _USE_CASE_VERSION?: number;
  _USE_CASE_AUDIENCES?: string;
}
