import { getCart } from './cart'
import { getClientId, getCookie, maybe } from '../../../shared/util'
import { getSessionProducts } from './products_viewed_in_session'
import { error } from '../common/log'
import { getHistoryArray, updateHistoryArray } from './params_history'
import { QueryHistoryEntry, VisitedPage } from '../../../shared/types'
import { getPurchasedProducts } from './purchased_products'
import { paramsHistoryKey, visitedPagesKey } from '../common/storage_keys'
import { getDeviceOverride } from '../triggers/device'
import { getEdgeTag } from '../../../shared/edgetag'
import { getTrafficSources, TrafficSource } from '../../../shared/utm'
import { dbRead, dbWrite } from '../../../shared/indexdb/db'
import { getGaId } from '../common/ga_cookie'

export interface CartItem {
  quantity: number;
  price: number;
  variantId: number;
  productId: number;
}

export interface ProductSeenInSession {
  handle: string;
  productId: number;
  variantId: number;
}

export interface TargetingContext {
  storeAlias: string;
  anonymousId: string;
  edgetag: string;
  oos?: boolean;
  price?: number;
  firstSeen?: number;
  iq?: number;
  cartToken?: string;
  clientId?: string;
  firstSession: boolean;
  gaId: string;
  landingPage?: string;
  path: string;
  host: string;
  query: string;
  cartAttributes?: string[];
  customerTags?: string[];
  trafficSources: Array<TrafficSource>;
  cartItems: Array<CartItem>;
  productsSeenInSession: Array<ProductSeenInSession>;
  isCustomer: boolean;
  deviceKind?: string;
  countryOverride?: string;
  visitedPages?: Array<VisitedPage>;
  queryHistory: Array<QueryHistoryEntry>;
  experienceId?: string;
  variantId?: string;
}

export function getTargetingContext(
  storeAlias: string,
  anonymousId: { id: string; firstSession: boolean, firstSeen?: number },
  targetingContextOverride?: Partial<TargetingContext>
): TargetingContext {
  const calculatedTargetingContext = _getTargetingContext()
  calculatedTargetingContext.storeAlias = storeAlias
  const clientId = getClientId()
  clientId && (calculatedTargetingContext.clientId = clientId)
  calculatedTargetingContext.anonymousId = anonymousId.id
  calculatedTargetingContext.firstSeen = anonymousId.firstSeen
  calculatedTargetingContext.firstSession = anonymousId.firstSession
  calculatedTargetingContext.gaId = getGaId()
  calculatedTargetingContext.customerTags = maybe(() => window.loomi_ctx.ctags!.slice(0, 20))
  calculatedTargetingContext.landingPage = maybe(() => window.loomi_ctx.session!.landing_page)
  const permId = getEdgeTag()
  permId && (calculatedTargetingContext.edgetag = permId)
  const previewOverrides = getPreviewOverrides()

  const queryHistory = getHistoryArray<QueryHistoryEntry>(paramsHistoryKey)
  const visitedPages = getPageVisits()
  const purchasedLineItems = getPurchasedProducts()

  const targetingContextOverrideTemp = {
    deviceKind: getDeviceOverride(),
    ...(targetingContextOverride || {}),
  }
  const isIPadPro = !!maybe(() => navigator.userAgent.includes('Macintosh') && 'ontouchend' in document)
  const cartCurrency = maybe(() => getCookie("cart_currency")) || maybe(() => getCart()!.currency) || ""
  if (maybe(() => window.loomi_ctx.current_product)) {
    calculatedTargetingContext.oos = maybe(() => window.loomi_ctx!.current_product!.oos)
    calculatedTargetingContext.price = maybe(() => window.loomi_ctx!.current_product!.price)
    calculatedTargetingContext.iq = maybe(() => window.loomi_ctx!.current_product!.variants!.reduce((p, c) => c.iq + p, 0))
  }

  return {
    cartCurrency,
    ...calculatedTargetingContext,
    queryHistory,
    isIPadPro,
    visitedPages,
    purchasedLineItems,
    ...(targetingContextOverrideTemp || {}),
    ...(previewOverrides || {}),
  }
}

function getPreviewOverrides() {
  try {
    const url = maybe(() => new URL(window.location.href))
    const forceOverride = maybe(() => url!.searchParams.get('forceOverride'))
    if (!!forceOverride) {
      const targeting = maybe(() => url!.searchParams.get('targeting'))
      if (!!targeting) {
        return JSON.parse(atob(targeting))
      }
    }
  } catch (e) {
    error('preview overrides', e)
  }
  return {}
}

export function parseCartItems() {
  return (
    maybe(() =>
      getCart()!.items.map(x => ({
        variantId: x.variant_id || 0,
        quantity: x.quantity || 0,
        productId: x.product_id || 0,
        price: x.price || 0,
      }))
    ) || []
  )
}

const getCartAttrs = () => {
  return maybe(() => ((window.vslyIntegrationType == 'spa'
    ? maybe(() => (getCart()!.attributes as Array<{
      key: string,
      value: string
    }>).map(x => x.key))
    : maybe(() => Object.keys(getCart()!.attributes))) || []).filter(x => !x.startsWith('vsly') && x.length < 20).slice(0, 15)
  )
}

function _getTargetingContext(): TargetingContext {
  const cartItems = parseCartItems()

  const ret = { path: window.location.pathname, host: window.location.hostname } as TargetingContext
  if (maybe(() => cartItems.length)! > 0) {
    ret.cartItems = cartItems
  }
  const cartToken = maybe(() => getCart()!.token)
  cartToken && (ret.cartToken = cartToken)
  const trafficSources = getTrafficSources()
  if (!!trafficSources) {
    ret.trafficSources = trafficSources
  }
  ret.productsSeenInSession = getSessionProducts()
  ret.isCustomer = !!getClientId()
  ret.query = window.location.search || ''
  const cartAttrs = getCartAttrs()
  if (cartAttrs) { ret.cartAttributes = cartAttrs }

  return ret
}

function getCurrentPage() {
  const x = new URL(window.location.href)
  return x.protocol + '//' + x.hostname + x.pathname
}

function getPageVisits() {
  const historyArray = getHistoryArray<VisitedPage>(visitedPagesKey)

  function valueOf(b: VisitedPage) {
    return new Date(b.ts).valueOf()
  }

  function cleanSearch() {
    const url = new URL(location.href);
    ['vslyvid', 'vslywgid', 'vslysid', 'lmi_preview', 'targeting', 'vsly_vid'].forEach(x => url.searchParams.delete(x))
    return url.search || ""
  }

  dbRead(visitedPagesKey).then((value?: { visits?: Array<VisitedPage> }) => {
    const visit = { ts: new Date(), name: getCurrentPage(), search: maybe(cleanSearch, "") }
    const hasSearch = !!visit.search
    const visits = (maybe(() => value!.visits) || historyArray || [])
      .filter(v => hasSearch ? !(v.name === visit.name && !v.search) : true)
      .filter(v => !(v.name === visit.name && (visit.search === v.search || (hasSearch && !v.search))))
      .sort((a, b) => valueOf(b) - valueOf(a))
      .slice(0, 500)
    visits.unshift(visit)
    dbWrite({ id: visitedPagesKey, visits }).catch()
  }).catch()

  // @deprecated: rm local storage visits after migration to client formula
  return updateHistoryArray<VisitedPage>(
    [{ ts: new Date(), name: getCurrentPage() }],
    historyArray,
    a => b => a.name === b.name,
    visitedPagesKey
  )
}
