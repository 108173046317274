import { Command } from './command'
import { ThemeChange } from '../models'
import { MutateDeclarativeOptions } from '../index'
import { buildContextFromOpts } from '../utils'

import { themeTest } from '../theme_test_utils'

export function newThemeTestCommand(
  id: string,
  themeTestChange: ThemeChange,
  options?: MutateDeclarativeOptions
): Command {
  const nop = () => false
  const _do = () =>
    themeTest(
      buildContextFromOpts(options!),
      themeTestChange.targetThemeId)
  return {
    id,
    kind: `themeTest`,
    isApplied: nop,
    do: _do,
    undo: nop,
    redoIfNeeded: nop,
    setDebugId: nop,
  }
}
