import { Command, findVisible, redoWhenElementIsDetached } from './command'
import {
  _IS_DEBUG,
  HtmlMutationKind,
  LOOMI_ATTR,
  LOOMI_WIDGET, LOOMI_WIDGET_VERSION,
} from '../models'
import { awaitCondition, maybe, getGoogleCDN } from '../../../../shared/util'
import { error } from '../../common/log'
import { hasEnoughHeightStrategy } from '../strategy_has_enough_height'
import { isDeferWidgetsEnabled } from '../../../../shared/fbits'

export const VISUALLY_GLOBAL_KEY = 'visually'
export const VISUALLY_WIDGETS_GLOBAL_KEY = 'widgets'

let globalIntersectionObserver: IntersectionObserver | undefined
if (!!window.IntersectionObserver) {
  globalIntersectionObserver = new IntersectionObserver((e, o) => {
    e.forEach((entry) => {
      if (entry.isIntersecting) {
        const mountPoint = entry.target as HTMLElement
        if (mountWidget(mountPoint)) {
          o.unobserve(mountPoint)
        }
      }
    })
  }, { root: null, rootMargin: window.vslyDeferWidgetsMargin || `100px`, threshold: 0.1 })
}

function mountWidget(mp: HTMLElement): boolean {
  const widgetId = mp.getAttribute(LOOMI_WIDGET)
  const widgetVersion = mp.getAttribute(LOOMI_WIDGET_VERSION)

  if (widgetId && widgetVersion) {
    const widgetKey = widgetId + widgetVersion
    awaitCondition(
      () =>
        // @ts-ignore
        maybe(() => !!(
          // @ts-ignore
          window[VISUALLY_GLOBAL_KEY][VISUALLY_WIDGETS_GLOBAL_KEY][widgetKey]
          // @ts-ignore
        )) && maybe(() => !!window.preact),
      50,
      500
    ).then(() => {
      // @ts-ignore
      maybe(() =>
        // @ts-ignore
        window[VISUALLY_GLOBAL_KEY][VISUALLY_WIDGETS_GLOBAL_KEY][widgetKey](
          `#${mp.id}`
        )
      )
    })
    return true
  }

  return false
}

export function newWidgetCommand(
  htmlMutationKind: HtmlMutationKind,
  id: string,
  selector: string,
  widgetId: string,
  env: any,
  debugId?: string,
  version: string = ``
): Command {
  const _debugId = debugId || id
  let isApplied = false
  const propsId = `vslyp-${id}`
  let origin: HTMLElement | null = null
  let widgetElement: HTMLElement | null = null
  let originalDisplayValues: string[] = []

  const _do = () => {
    if (isApplied) return
    downloadWidget(widgetId, version)
    if (htmlMutationKind === 'replace') {
      replace()
    } else {
      append()
    }

    if (!(isDeferWidgetsEnabled() && globalIntersectionObserver)) {
      applyDefault()
    }

    isApplied = true
  }

  const applyDefault = () => {
    const widgetKey = widgetId + version

    awaitCondition(
      () =>
        // @ts-ignore
        maybe(() => !!(
          // @ts-ignore
          window[VISUALLY_GLOBAL_KEY][VISUALLY_WIDGETS_GLOBAL_KEY][widgetKey]
          // @ts-ignore
        )) && maybe(() => !!window.preact),
      50,
      500
    ).then(() => {
      hasEnoughHeightStrategy(origin as HTMLElement, id)
      // @ts-ignore
      maybe(() =>
        // @ts-ignore
        window[VISUALLY_GLOBAL_KEY][VISUALLY_WIDGETS_GLOBAL_KEY][widgetKey](`#${id}`)
      )
    })
  }

  const _undo = () => {
    if (!isApplied) return
    widgetElement && widgetElement.remove()
    const props = document.getElementById(propsId)
    props && props.remove()
    if (htmlMutationKind === 'replace') {
      if (origin && origin.children && origin.children.length > 0) {
        let idx = 0
        // @ts-ignore
        for (const child of origin.children) {
          const childElem = child as HTMLElement
          childElem.style.display = originalDisplayValues[idx]
          idx += 1
        }
      }
    }
    isApplied = false
  }

  const _redo = () => {
    const resp = redoWhenElementIsDetached(_do, _undo, selector, origin)
    if (resp.element) origin = resp.element
    return resp.isDetached
  }

  const replace = () => {
    origin = findVisible(selector)
    if (!origin) {
      error(
        'vsly',
        `failed to find element with selector: ${selector} when trying to replace with widget`
      )
      return
    }

    originalDisplayValues = []
    if (origin.children && origin.children.length > 0) {
      // @ts-ignore
      for (const child of origin.children) {
        const childElem = child as HTMLElement
        if (childElem && !childElem.getAttribute('preact')) {
          originalDisplayValues.push(childElem.style.display)
          childElem.style.display = `none`
        }
      }
    }

    resolveHostElement()
    origin.appendChild(widgetElement!!)
    if (isDeferWidgetsEnabled() && globalIntersectionObserver) {
      globalIntersectionObserver.observe(widgetElement!!)
    }
  }

  const append = () => {
    origin = findVisible(selector)
    if (!origin) {
      error(
        'vsly',
        `failed to find element with selector: ${selector} when trying to append widget`
      )
      return
    }

    resolveHostElement()

    if (htmlMutationKind === `appendBefore`) {
      origin.insertAdjacentElement('beforebegin', widgetElement!!)
    } else {
      origin.insertAdjacentElement('afterend', widgetElement!!)
    }
    if (isDeferWidgetsEnabled() && globalIntersectionObserver) {
      globalIntersectionObserver.observe(widgetElement!!)
    }
  }

  const resolveHostElement = () => {
    widgetElement = document.getElementById(id)
    if (!widgetElement) {
      widgetElement = document.createElement('section')
      widgetElement.style.all = 'unset'
      widgetElement.style.width = '100%'
      widgetElement.setAttribute('preact', '1')
      _IS_DEBUG && widgetElement.setAttribute(LOOMI_ATTR, _debugId)
      widgetElement.setAttribute(LOOMI_WIDGET, widgetId)
      widgetElement.setAttribute(LOOMI_WIDGET_VERSION, version)
      widgetElement.id = id

      const props = document.createElement('script') as HTMLScriptElement
      props.id = propsId
      props.setAttribute('type', 'text/props')
      props.innerHTML = JSON.stringify(env)
      maybe(() => document.body.appendChild(props))
    }
  }

  return {
    id,
    isApplied: () => isApplied,
    kind: `widget`,
    do: _do,
    undo: _undo,
    redoIfNeeded: _redo,
  }
}

export function downloadWidget(widgetId: string, version: string) {
  const key = widgetId + version
  const scriptId = `vslyw-${key}`
  if (!document.getElementById(scriptId)) {
    const script = document.createElement(`script`)
    script.id = scriptId
    script.type = 'text/javascript'
    const bunnyCDN = maybe(() => window.visually.flags['bunny-cdn'])
    const googleCDN = getGoogleCDN()
    script.src = `https://${bunnyCDN ? 'visually-sdk.b-cdn.net' : googleCDN}/widgets/${key}.js`
    document.head.appendChild(script)
  }
}
