import { Context } from '../../../shared/types'
import { isFbEnabled } from '../../../shared/fbits'
import { awaitCondition, hidePreviewBarIframe, maybe, showPreviewBarIframe } from '../../../shared/helpers'
import { getLogger } from '../../../analytics/core/log/log'
import { redirectTest, REDIRECT_BACK_TO_MANI } from './utils'

export async function cleanupThemeTest() {
  return themeTest({ _USE_CASE: REDIRECT_BACK_TO_MANI, _USE_CASE_VARIANT: '', _USE_CASE_VERSION: 1 } as Context, 0, 0)
}

export async function themeTest(
  ctx: Context,
  targetThemeId?: number,
  redirectAfter = 10
) {
  if (!isFbEnabled('theme-testing')) return
  if (isFbEnabled('kill-theme-testing')) return

  const url = new URL(document.location.href)
  if (shouldNotRunThemeTest(url)) {
    return
  }
  if (!!targetThemeId) {
    hidePreviewBarIframe()
  }
  try {
    await awaitThemeIsAvailable()
  } catch (e) {
    showPreviewBarIframe()
    return
  }

  const currentTheme = window.Shopify.theme
  if (unTestedTheme(currentTheme)) {
    showPreviewBarIframe()
    return
  }
  if (isOnTargetTheme(currentTheme, targetThemeId)) {
    getLogger().info(`OnTargetTheme`, { targetThemeId, ctx })
    return
  }

  url.searchParams.append('preview_theme_id', `${targetThemeId || ''}`)

  redirectTest(ctx, url.href, redirectAfter, true, 'session')
}

function shouldNotRunThemeTest(url: URL) {
  const ignoreThemeTestKey = 'vsly_disableThemeTest'
  const hasSkipThemeTestQueryParam = !!url.searchParams.get('disableThemeTest') || !!sessionStorage.getItem(ignoreThemeTestKey)
  if (hasSkipThemeTestQueryParam) {
    sessionStorage.setItem(ignoreThemeTestKey, '1')
  }
  return maybe(() => window.Shopify.designMode) || hasSkipThemeTestQueryParam
}

const awaitThemeIsAvailable = () => awaitCondition(() => maybe(() => window.Shopify.theme.id > 0)!!, 20, 150)
const unTestedTheme = (currentTheme: { role: string, id: number }) => {
  return currentTheme.role != 'main' && !window.loomi_ctx.testedThemes!.includes(currentTheme.id)
}
const isOnTargetTheme = (currentTheme: { role: string, id: number }, targetThemeId?: number) => {
  return currentTheme.id === targetThemeId || (currentTheme.role === 'main' && !targetThemeId)
}
